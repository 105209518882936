import { useContext, useCallback, useMemo } from 'react';
import idx from 'idx';
import { useMutation } from '@apollo/react-hooks';

import { CREATE_PAGE_EXPERIENCE, ExperienceTrackerContext } from '@confluence/experience-tracker';
import type { OnContentUpdatedResponse } from '@confluence/create-dialog-context';
import { CreateDialogContext } from '@confluence/create-dialog-context';
import { addQueryParams } from '@confluence/route-manager';
import {
	CREATED_WITH_TEMPLATE_QUERY_PARAM_NAME,
	IN_EDITOR_TEMPLATES_QUERY_OPEN,
	IN_EDITOR_TEMPLATES_QUERY_PARAM_NAME,
} from '@confluence/template-utils';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';

import type { Creatable, CanHandle, Handle, Template } from '../createContentFromTemplateTypes';
import { failExperienceTracking, succeedExperienceTracking } from '../experienceTracking';
import { processDraftUrl } from '../helpers';
import type {
	CreatePageByBlueprintMutation as CreateBlueprintResponse,
	CreatePageByBlueprintMutationVariables,
} from '../queries/__types__/CreatePageByBlueprintMutation';
import { CreatePageByBlueprintMutation } from '../queries/CreatePageByBlueprint.experimentalgraphql';
import type {
	UpdateDraftByBlueprintMutation as UpdateDraftByBlueprintResponse,
	UpdateDraftByBlueprintMutation_experimentalUpdateDraftWithBlueprint as UpdateDraftByBlueprintResponseData,
	UpdateDraftByBlueprintMutationVariables,
} from '../queries/__types__/UpdateDraftByBlueprintMutation';
import { UpdateDraftByBlueprintMutation } from '../queries/UpdateDraftByBlueprint.experimentalgraphql';

import { useNavigation } from './useNavigation';

const hasWizard = (template: Template) => !!idx(template, (_) => _.hasWizard);

export const useBlueprintCreatable = (): Creatable => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	const navigate = useNavigation();
	const { openBlueprintWizard } = useContext(CreateDialogContext);
	const isEditorPage = useIsEditorPage();

	const [createFromBlueprintMutation] = useMutation<
		CreateBlueprintResponse,
		CreatePageByBlueprintMutationVariables
	>(CreatePageByBlueprintMutation);

	const [updateFromBlueprintMutation] = useMutation<
		UpdateDraftByBlueprintResponse,
		UpdateDraftByBlueprintMutationVariables
	>(UpdateDraftByBlueprintMutation);

	const canHandle: CanHandle = useCallback(
		(template) =>
			!!(
				template.blueprintModuleCompleteKey &&
				template.itemModuleCompleteKey &&
				template.contentBlueprintId
			),
		[],
	);

	const handle: Handle = useCallback(
		async (template, spaceKey, options = {}) => {
			const {
				templateOriginSpaceKey,
				contentId,
				parentPageId,
				title,
				onContentUpdated,
				onVariableInputError,
				beforeUpdateRequest,
				forceTemplatePanelOpen,
				openContentInNewTab,
			} = options;

			const handleUpdateContentResponse = (
				response: UpdateDraftByBlueprintResponseData | OnContentUpdatedResponse | null,
			) => {
				const isLivePageResponse = response?.content?.subType === 'live';
				const isEditorV2Response = response?.content?.metadata?.properties?.editor?.value === 'v2';
				const onContentUpdatedAndResponseExists = onContentUpdated && response;

				if (isLivePageResponse || !isEditorV2Response || !onContentUpdatedAndResponseExists) {
					navigate(processDraftUrl(response?.content?._links?.editui), {
						isReplaceAction: true,
					});
				} else {
					onContentUpdated(response, template, spaceKey, contentId || '');
				}
			};

			if (hasWizard(template)) {
				// Since the wizard will make the actual update call, we need it to succeed/fail the create-content-from-template
				// experience as part of these callbacks
				const handleUpdateContentResponseWithExperienceTracking = (
					response: UpdateDraftByBlueprintResponseData | OnContentUpdatedResponse | null,
				) => {
					onContentUpdated && handleUpdateContentResponse(response);
					succeedExperienceTracking();
				};
				const onVariableInputErrorWithExperienceTracking = (e) => {
					onVariableInputError && onVariableInputError(e);
					failExperienceTracking(e);
				};
				openBlueprintWizard({
					spaceKey,
					templateOriginSpaceKey,
					title,
					blueprint: {
						itemModuleCompleteKey: template.itemModuleCompleteKey as string, // know it exists from canHandle
						contentBlueprintId: template.contentBlueprintId as string, // know it exists from canHandle
					},
					parentPageId: parentPageId || '',
					...(contentId && { contentId }),
					onContentUpdated: handleUpdateContentResponseWithExperienceTracking,
					onError: onVariableInputErrorWithExperienceTracking,
					beforeUpdateRequest,
				});
				return false;
			} else if (contentId) {
				beforeUpdateRequest && beforeUpdateRequest();
				const updateFromBlueprintResponse = await updateFromBlueprintMutation({
					variables: {
						contentId,
						spaceKey,
						title,
						blueprint: JSON.stringify(template),
					},
				});
				const response =
					idx(updateFromBlueprintResponse, (_) => _.data.experimentalUpdateDraftWithBlueprint) ||
					null;

				handleUpdateContentResponse(response);
			} else {
				// ToDo: Set attemptSpaUrlResponse to always be true and verify that there are no longer editor->editor
				//  transition bugs (https://product-fabric.atlassian.net/browse/MODES-3741)
				//  remove the call to isEditorPage if successful
				const createFromBlueprintResponse = await createFromBlueprintMutation({
					variables: {
						spaceKey,
						title,
						parentPageId: options.parentPageId,
						blueprint: JSON.stringify(template),
						attemptSpaUrlResponse: !isEditorPage,
					},
				});
				const draftUrl = idx(
					createFromBlueprintResponse,
					(_) => _.data.experimentalPublishPageByBlueprint.links.draftUrl,
				);

				if (forceTemplatePanelOpen) {
					navigate(
						addQueryParams(processDraftUrl(draftUrl), {
							[IN_EDITOR_TEMPLATES_QUERY_PARAM_NAME]: IN_EDITOR_TEMPLATES_QUERY_OPEN,
							[CREATED_WITH_TEMPLATE_QUERY_PARAM_NAME]: 'true',
						}),
						{
							openContentInNewTab,
						},
					);
				} else {
					navigate(
						addQueryParams(processDraftUrl(draftUrl), {
							[CREATED_WITH_TEMPLATE_QUERY_PARAM_NAME]: 'true',
						}),
						{
							openContentInNewTab,
						},
					);
				}

				experienceTracker.succeed({ name: CREATE_PAGE_EXPERIENCE });
			}
			return true;
		},
		[
			createFromBlueprintMutation,
			updateFromBlueprintMutation,
			isEditorPage,
			experienceTracker,
			navigate,
			openBlueprintWizard,
		],
	);

	return useMemo(() => ({ canHandle, handle }), [canHandle, handle]);
};
