import gql from 'graphql-tag';

export const CreateFabricBlogpostMutation = gql`
	mutation CreateFabricBlogpostMutation($spaceKey: String!, $returnSpaUrl: Boolean!, $title: String)
	@experimental {
		experimentalCreateFabricBlogpost(
			spaceKey: $spaceKey
			returnSpaUrl: $returnSpaUrl
			title: $title
		) {
			links {
				draftUrl
			}
		}
	}
`;
