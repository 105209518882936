import gql from 'graphql-tag';

export const GetTemplateBodyContentQuery = gql`
	query GetTemplateBodyContentQuery($templateId: String!) {
		template(contentTemplateId: $templateId) {
			templateId
			name
			editorVersion
			body {
				atlas_doc_format {
					value
				}
			}
		}
	}
`;
