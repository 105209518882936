import gql from 'graphql-tag';

export const UpdateDraftByBlueprintMutation = gql`
	mutation UpdateDraftByBlueprintMutation(
		$contentId: String!
		$spaceKey: String!
		$blueprint: String!
		$parentPageId: String
		$title: String
	) @experimental {
		experimentalUpdateDraftWithBlueprint(
			spaceKey: $spaceKey
			contentId: $contentId
			blueprint: $blueprint
			parentPageId: $parentPageId
			title: $title
		) {
			content {
				id
				type
				title
				subType
				space {
					id
					key
				}
				_links {
					editui
				}
				metadata {
					properties {
						editor {
							value
						}
					}
				}
			}
		}
	}
`;
