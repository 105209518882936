import { useContext, useCallback, useMemo } from 'react';

import { CREATE_PAGE_EXPERIENCE, ExperienceTrackerContext } from '@confluence/experience-tracker';
import { SPACE_CALENDAR } from '@confluence/named-routes';

import type { Creatable, CanHandle, Handle } from '../createContentFromTemplateTypes';

import { useNavigation } from './useNavigation';

export const useCalendarCreatable = (): Creatable => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	const navigate = useNavigation();

	const canHandle: CanHandle = useCallback(
		(template) =>
			template.itemModuleCompleteKey ===
			'com.atlassian.confluence.extra.team-calendars:tc-on-space-blueprint-webitem',
		[],
	);

	const handle: Handle = useCallback(
		async (_, spaceKey, options = {}) => {
			const { openContentInNewTab } = options;

			navigate(SPACE_CALENDAR.toUrl({ spaceKey }), {
				openContentInNewTab,
			});

			experienceTracker.succeed({ name: CREATE_PAGE_EXPERIENCE });
			return true;
		},
		[experienceTracker, navigate],
	);

	return useMemo(() => ({ canHandle, handle }), [canHandle, handle]);
};
