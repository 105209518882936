import { useCallback } from 'react';

import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';

type navigationOptions = {
	openContentInNewTab?: boolean;
	isReplaceAction?: boolean;
	forceReload?: boolean;
};

type useNavigationResult = (url: string, options?: navigationOptions) => void;

export const useNavigation = (): useNavigationResult => {
	const { push, replace } = useRouteActions();

	return useCallback(
		(url: string, options?: navigationOptions) => {
			const { openContentInNewTab, isReplaceAction, forceReload } = options || {};

			if (openContentInNewTab) {
				window.open(url, '_blank', 'noreferrer');
			} else if (isReplaceAction) {
				replace(url, forceReload);
			} else {
				push(url, forceReload);
			}
		},
		[push, replace],
	);
};
