import gql from 'graphql-tag';

export const GetPersonalSpaceKeyQuery = gql`
	query GetPersonalSpaceKeyQuery {
		user(current: true) {
			id
			confluence {
				hasPersonalSpace
				space {
					id
					key
				}
			}
		}
	}
`;
