import { CONTEXT_PATH } from '@confluence/named-routes';

export const processDraftUrl = (url: string | undefined | null): string => {
	if (!url) {
		throw new Error('No editor url for new content');
	}
	if (url.startsWith(CONTEXT_PATH)) {
		return url;
	}
	return `${CONTEXT_PATH}${url}`;
};
